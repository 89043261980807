
import  GLightbox from "glightbox";
import { InitNavigationMenu } from "./navigation-menu.js";
import { ToggleNavigation } from "./mobileNavigation.js";

function initObservers() {
  // Register IntersectionObserver
  const io = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        // Add 'active' class if observation target is inside viewport
        entry.target.classList.add("inView");
      } else {
        // Remove 'active' class otherwise
        entry.target.classList.remove("inView");
      }
    });
  });

  // Declares what to observe, and observes its properties.
  const boxElList = document.querySelectorAll(".observeInView");
  boxElList.forEach((el) => {
    io.observe(el);
  });

}







document.addEventListener("DOMContentLoaded", function(){
  'use strict';


  document.body.parentElement.classList.remove("no-js");

  if(window.innerWidth < 722) {

    new ToggleNavigation("#mobile-menu-button","#mobile-menu-button");
    const navigationMenu = new InitNavigationMenu();

  }
  initObservers();
  const lightbox = GLightbox({selector: ':is(.ce_gallery,.ce_image,.ce_text,.content-gallery) a[data-lightbox]' });



});

